@font-face {
    font-family: 'Poppins';
    src: url('https://cdn.avt.pl/fonts/PoppinsLight.eot');
    src: url('https://cdn.avt.pl/fonts/PoppinsLight.eot') format('embedded-opentype'),
         url('https://cdn.avt.pl/fonts/PoppinsLight.woff2') format('woff2'),
         url('https://cdn.avt.pl/fonts/PoppinsLight.woff') format('woff'),
         url('https://cdn.avt.pl/fonts/PoppinsLight.ttf') format('truetype'),
         url('https://cdn.avt.pl/fonts/PoppinsLight.svg#PoppinsLight') format('svg');
    font-weight: 300;
}

@font-face {
    font-family: 'Poppins';
    src: url('https://cdn.avt.pl/fonts/PoppinsRegular.eot');
    src: url('https://cdn.avt.pl/fonts/PoppinsRegular.eot') format('embedded-opentype'),
         url('https://cdn.avt.pl/fonts/PoppinsRegular.woff2') format('woff2'),
         url('https://cdn.avt.pl/fonts/PoppinsRegular.woff') format('woff'),
         url('https://cdn.avt.pl/fonts/PoppinsRegular.ttf') format('truetype'),
         url('https://cdn.avt.pl/fonts/PoppinsRegular.svg#PoppinsRegular') format('svg');
    font-weight: 400;
}

@font-face {
    font-family: 'Poppins';
    src: url('https://cdn.avt.pl/fonts/PoppinsMedium.eot');
    src: url('https://cdn.avt.pl/fonts/PoppinsMedium.eot') format('embedded-opentype'),
         url('https://cdn.avt.pl/fonts/PoppinsMedium.woff2') format('woff2'),
         url('https://cdn.avt.pl/fonts/PoppinsMedium.woff') format('woff'),
         url('https://cdn.avt.pl/fonts/PoppinsMedium.ttf') format('truetype'),
         url('https://cdn.avt.pl/fonts/PoppinsMedium.svg#PoppinsMedium') format('svg');
    font-weight: 500;
}

@font-face {
    font-family: 'Poppins';
    src: url('https://cdn.avt.pl/fonts/PoppinsSemiBold.eot');
    src: url('https://cdn.avt.pl/fonts/PoppinsSemiBold.eot') format('embedded-opentype'),
         url('https://cdn.avt.pl/fonts/PoppinsSemiBold.woff2') format('woff2'),
         url('https://cdn.avt.pl/fonts/PoppinsSemiBold.woff') format('woff'),
         url('https://cdn.avt.pl/fonts/PoppinsSemiBold.ttf') format('truetype'),
         url('https://cdn.avt.pl/fonts/PoppinsSemiBold.svg#PoppinsSemiBold') format('svg');
    font-weight: 600;
}

@font-face {
    font-family: 'Poppins';
    src: url('https://cdn.avt.pl/fonts/PoppinsBold.eot');
    src: url('https://cdn.avt.pl/fonts/PoppinsBold.eot') format('embedded-opentype'),
         url('https://cdn.avt.pl/fonts/PoppinsBold.woff2') format('woff2'),
         url('https://cdn.avt.pl/fonts/PoppinsBold.woff') format('woff'),
         url('https://cdn.avt.pl/fonts/PoppinsBold.ttf') format('truetype'),
         url('https://cdn.avt.pl/fonts/PoppinsBold.svg#PoppinsBold') format('svg');
    font-weight: 700;
}
