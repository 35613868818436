@import 'base/typography';

* {
    box-sizing: border-box;
}
html body {
    margin: 0;
    padding: 0;
    font-family: 'Poppins', sans-serif;
}
form, input, select, button {
    font-family: 'Poppins', sans-serif;
}
.Table.table.table-striped.table-bordered {
    &, * {
        color: #fff;
    }
}
.error-body {
    background-image: url("/templates/main/images/background-error.jpg");
    background-position: center;
    background-size: cover;
    background-attachment: fixed;
    background-repeat: no-repeat;
    &:after {
        content: "";
        position: fixed;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        background-color: rgba(0,0,0,0.6);
    }
}
.error-header {
    width: 100%;
    background-color: #fff;
    text-align: center;
    position: relative;
    z-index: 3;
    height: 80px;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    align-content: center;
    .logo {
        width: 100%;
        &-box {
            max-width: 165px;
            width: 100%;
        }
    }
}
.error-message {
    margin: 0;
    padding: 0;
    font-size: 20px;
    padding: 0 15px;
    @media(min-width: 576px) {
        font-size: 30px;
    }
}
.error-code {
    margin: 0;
    padding: 0;
    font-size: 120px;
    line-height: 130px;
    @media(min-width: 576px) {
        font-size: 240px;
        line-height: 250px;
    }
}
.error-hint {
    padding: 0 15px;
    font-size: 20px;
    @media(min-width: 576px) {
        font-size: 30px;
    }
    a {
        text-decoration: none;
        font-weight: 700;
        color: #fff;
        transition: color .2s ease-in-out;
        &:hover {
            color: #D63A3A;
        }
    }
}
.search-form {
    padding: 0 15px;
}
.error-main {
    text-align: center;
    position: relative;
    z-index: 3;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    align-content: center;
    min-height: calc(100vh - 80px);
    color: #fff;
    padding: 50px 0;
}
.search-input {
    background: none;
    border: none;
    outline: none;
    border-bottom: 4px solid #fff;
    background-color: transparent;
    display: block;
    margin: 0 auto 30px;
    width: 100%;
    max-width: 440px;
    text-align: center;
    color: #fff;
    height: 55px;
    font-size: 20px;
    @media(min-width: 576px) {
        font-size: 30px;
    }
    &::placeholder {
        color: #fff;
        opacity: 1;
    }
}
.search-button {
    height: 60px;
    width: 100%;
    max-width: 205px;
    border: none;
    outline: none;
    background-color: #D63A3A;
    color: #fff;
    font-weight: 700;
    font-size: 18px;
    letter-spacing: 0.1em;
    text-transform: uppercase;
    cursor: pointer;
}
